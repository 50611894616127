if (module.hot) {
	module.hot.accept();
}

var $ = require("jquery");
var jQuery = require("jquery");
global.jQuery = require('jquery');
var slick =  require('slick-carousel');
var AOS = require('aos');


jQuery(function() {
	initMobileNav();
	initTabs();
	initSlickCarousel();
	initSmoothScroll();
});

function initSmoothScroll() {
	var links = jQuery('.scroll');
	links.on('click', function() {
		var link = jQuery(this);
		var href = link.attr('href');
		jQuery('html, body',).animate({
			scrollTop: jQuery(href).offset().top
		});
		if (jQuery('body').hasClass('nav-active')) {
			jQuery('body').removeClass('nav-active');
		}
	});
}


// mobile menu init
function initMobileNav() {
	jQuery('body').mobileNav({
		menuActiveClass: 'nav-active',
		menuOpener: '.nav-opener',
		hideOnClickOutside: true,
		menuDrop: '.nav-drop'
	});
}


// content tabs init
function initTabs() {
	jQuery('.tabset').tabset({
		tabLinks: 'a',
		activeClass: 'active',
		defaultTab: true,
		autoHeight: true,
		animSpeed: '100',
	});
}


// slick init
function initSlickCarousel() {
	var win = jQuery(window);
	jQuery('.tabset').each(function() {
		var holder = jQuery(this);
		var holderClasses = holder.attr('class');
		var holderHtml = holder.html();
		var mode = null;
		holder.on('init', function(e, slick) {
			slick.slickGoTo(0, true);
		});
		var refresh = function() {
			switch(mode) {
				case 'desktop':
				holder.attr('class',holderClasses)
				.html(holderHtml);
				break;
				case 'mobile':
				holder.slick({
					slidesToScroll: 1,
					rows: 0,
					arrows: false,
					infinite: false,
					dots: false,
					focusOnSelect: false,
					variableWidth: true,
					prevArrow: '<button class="slick-prev">Previous</button>',
					nextArrow: '<button class="slick-next">Next</button>'
				});
				break;
			}
			initTabs()
		};
		var resizeHandler = function() {
			if (window.innerWidth > 767) {
				if (mode !== 'desktop') {
					mode = 'desktop';
					refresh();
				}
			} else {
				if (mode !== 'mobile') {
					mode = 'mobile';
					refresh();
				}
			}
		};
		win.on('resize orientationchange', resizeHandler);
		resizeHandler();
	});

	jQuery('.info-list').each(function() {
		var holder = jQuery(this);
		var holderClasses = holder.attr('class');
		var holderHtml = holder.html();
		var mode = null;
		holder.on('init', function(e, slick) {
			slick.slickGoTo(0, true);
		});
		var refresh = function() {
			switch(mode) {
				case 'desktop':
				holder.attr('class',holderClasses)
				.html(holderHtml);
				break;
				case 'mobile':
				holder.slick({
					slidesToScroll: 1,
					rows: 0,
					infinite: false,
					arrows: false,
					dots: false,
					focusOnSelect: false,
					prevArrow: '<button class="slick-prev">Previous</button>',
					nextArrow: '<button class="slick-next">Next</button>'
				});
				break;
			}
		};
		var resizeHandler = function() {
			if (window.innerWidth > 767) {
				if (mode !== 'desktop') {
					mode = 'desktop';
					refresh();
				}
			} else {
				if (mode !== 'mobile') {
					mode = 'mobile';
					refresh();
				}
			}
		};
		win.on('resize orientationchange', resizeHandler);
		resizeHandler();
	});

}


/*
 * Simple Mobile Navigation
 */
 ;(function($) {
 	function MobileNav(options) {
 		this.options = $.extend({
 			container: null,
 			hideOnClickOutside: false,
 			menuActiveClass: 'nav-active',
 			menuOpener: '.nav-opener',
 			menuDrop: '.nav-drop',
 			toggleEvent: 'click',
 			outsideClickEvent: 'click touchstart pointerdown MSPointerDown'
 		}, options);
 		this.initStructure();
 		this.attachEvents();
 	}
 	MobileNav.prototype = {
 		initStructure: function() {
 			this.page = $('html');
 			this.container = $(this.options.container);
 			this.opener = this.container.find(this.options.menuOpener);
 			this.drop = this.container.find(this.options.menuDrop);
 		},
 		attachEvents: function() {
 			var self = this;

 			if(activateResizeHandler) {
 				activateResizeHandler();
 				activateResizeHandler = null;
 			}

 			this.outsideClickHandler = function(e) {
 				if(self.isOpened()) {
 					var target = $(e.target);
 					if(!target.closest(self.opener).length && !target.closest(self.drop).length) {
 						self.hide();
 					}
 				}
 			};

 			this.openerClickHandler = function(e) {
 				e.preventDefault();
 				self.toggle();
 			};

 			this.opener.on(this.options.toggleEvent, this.openerClickHandler);
 		},
 		isOpened: function() {
 			return this.container.hasClass(this.options.menuActiveClass);
 		},
 		show: function() {
 			this.container.addClass(this.options.menuActiveClass);
 			if(this.options.hideOnClickOutside) {
 				this.page.on(this.options.outsideClickEvent, this.outsideClickHandler);
 			}
 		},
 		hide: function() {
 			this.container.removeClass(this.options.menuActiveClass);
 			if(this.options.hideOnClickOutside) {
 				this.page.off(this.options.outsideClickEvent, this.outsideClickHandler);
 			}
 		},
 		toggle: function() {
 			if(this.isOpened()) {
 				this.hide();
 			} else {
 				this.show();
 			}
 		},
 		destroy: function() {
 			this.container.removeClass(this.options.menuActiveClass);
 			this.opener.off(this.options.toggleEvent, this.clickHandler);
 			this.page.off(this.options.outsideClickEvent, this.outsideClickHandler);
 		}
 	};

 	var activateResizeHandler = function() {
 		var win = $(window),
 		doc = $('html'),
 		resizeClass = 'resize-active',
 		flag, timer;
 		var removeClassHandler = function() {
 			flag = false;
 			doc.removeClass(resizeClass);
 		};
 		var resizeHandler = function() {
 			if(!flag) {
 				flag = true;
 				doc.addClass(resizeClass);
 			}
 			clearTimeout(timer);
 			timer = setTimeout(removeClassHandler, 500);
 		};
 		win.on('resize orientationchange', resizeHandler);
 	};

 	$.fn.mobileNav = function(opt) {
 		var args = Array.prototype.slice.call(arguments);
 		var method = args[0];

 		return this.each(function() {
 			var $container = jQuery(this);
 			var instance = $container.data('MobileNav');

 			if (typeof opt === 'object' || typeof opt === 'undefined') {
 				$container.data('MobileNav', new MobileNav($.extend({
 					container: this
 				}, opt)));
 			} else if (typeof method === 'string' && instance) {
 				if (typeof instance[method] === 'function') {
 					args.shift();
 					instance[method].apply(instance, args);
 				}
 			}
 		});
 	};
 }(jQuery));

/*
 * jQuery Tabs plugin
 */

 ;(function($, $win) {
 	'use strict';

 	function Tabset($holder, options) {
 		this.$holder = $holder;
 		this.options = options;

 		this.init();
 	}

 	Tabset.prototype = {
 		init: function() {
 			this.$tabLinks = this.$holder.find(this.options.tabLinks);

 			this.setStartActiveIndex();
 			this.setActiveTab();

 			if (this.options.autoHeight) {
 				this.$tabHolder = $(this.$tabLinks.eq(0).attr(this.options.attrib)).parent();
 			}

 			this.makeCallback('onInit', this);
 		},

 		setStartActiveIndex: function() {
 			var $classTargets = this.getClassTarget(this.$tabLinks);
 			var $activeLink = $classTargets.filter('.' + this.options.activeClass);
 			var $hashLink = this.$tabLinks.filter('[' + this.options.attrib + '="' + location.hash + '"]');
 			var activeIndex;

 			if (this.options.checkHash && $hashLink.length) {
 				$activeLink = $hashLink;
 			}

 			activeIndex = $classTargets.index($activeLink);

 			this.activeTabIndex = this.prevTabIndex = (activeIndex === -1 ? (this.options.defaultTab ? 0 : null) : activeIndex);
 		},

 		setActiveTab: function() {
 			var self = this;

 			this.$tabLinks.each(function(i, link) {
 				var $link = $(link);
 				var $classTarget = self.getClassTarget($link);
 				var $tab = $($link.attr(self.options.attrib));

 				if (i !== self.activeTabIndex) {
 					$classTarget.removeClass(self.options.activeClass);
 					$tab.addClass(self.options.tabHiddenClass).removeClass(self.options.activeClass);
 				} else {
 					$classTarget.addClass(self.options.activeClass);
 					$tab.removeClass(self.options.tabHiddenClass).addClass(self.options.activeClass);
 				}

 				self.attachTabLink($link, i);
 			});
 		},

 		attachTabLink: function($link, i) {
 			var self = this;

 			$link.on(this.options.event + '.tabset', function(e) {
 				e.preventDefault();

 				if (self.activeTabIndex === self.prevTabIndex && self.activeTabIndex !== i) {
 					self.activeTabIndex = i;
 					self.switchTabs();
 				}
 				if (self.options.checkHash) {
 					location.hash = jQuery(this).attr('href').split('#')[1]
 				}
 			});
 		},

 		resizeHolder: function(height) {
 			var self = this;

 			if (height) {
 				this.$tabHolder.height(height);
 				setTimeout(function() {
 					self.$tabHolder.addClass('transition');
 				}, 10);
 			} else {
 				self.$tabHolder.removeClass('transition').height('');
 			}
 		},

 		switchTabs: function() {
 			var self = this;

 			var $prevLink = this.$tabLinks.eq(this.prevTabIndex);
 			var $nextLink = this.$tabLinks.eq(this.activeTabIndex);

 			var $prevTab = this.getTab($prevLink);
 			var $nextTab = this.getTab($nextLink);

 			$prevTab.removeClass(this.options.activeClass);

 			if (self.haveTabHolder()) {
 				this.resizeHolder($prevTab.outerHeight());
 			}

 			setTimeout(function() {
 				self.getClassTarget($prevLink).removeClass(self.options.activeClass);

 				$prevTab.addClass(self.options.tabHiddenClass);
 				$nextTab.removeClass(self.options.tabHiddenClass).addClass(self.options.activeClass);

 				self.getClassTarget($nextLink).addClass(self.options.activeClass);
 				self.makeCallback('onChange', self);

 				if (self.haveTabHolder()) {
 					self.resizeHolder($nextTab.outerHeight());

 					setTimeout(function() {
 						self.resizeHolder();
 						self.prevTabIndex = self.activeTabIndex;
 					}, self.options.animSpeed);
 				} else {
 					self.prevTabIndex = self.activeTabIndex;
 				}
 			}, this.options.autoHeight ? this.options.animSpeed : 1);
 		},

 		getClassTarget: function($link) {
 			return this.options.addToParent ? $link.parent() : $link;
 		},

 		getActiveTab: function() {
 			return this.getTab(this.$tabLinks.eq(this.activeTabIndex));
 		},

 		getTab: function($link) {
 			return $($link.attr(this.options.attrib));
 		},

 		haveTabHolder: function() {
 			return this.$tabHolder && this.$tabHolder.length;
 		},

 		destroy: function() {
 			var self = this;

 			this.$tabLinks.off('.tabset').each(function() {
 				var $link = $(this);

 				self.getClassTarget($link).removeClass(self.options.activeClass);
 				$($link.attr(self.options.attrib)).removeClass(self.options.activeClass + ' ' + self.options.tabHiddenClass);
 			});

 			this.$holder.removeData('Tabset');
 		},

 		makeCallback: function(name) {
 			if (typeof this.options[name] === 'function') {
 				var args = Array.prototype.slice.call(arguments);
 				args.shift();
 				this.options[name].apply(this, args);
 			}
 		}
 	};

 	$.fn.tabset = function(opt) {
 		var args = Array.prototype.slice.call(arguments);
 		var method = args[0];

 		var options = $.extend({
 			activeClass: 'active',
 			addToParent: false,
 			autoHeight: false,
 			checkHash: false,
 			defaultTab: true,
 			animSpeed: 500,
 			tabLinks: 'a',
 			attrib: 'href',
 			event: 'click',
 			tabHiddenClass: 'js-tab-hidden'
 		}, opt);
 		options.autoHeight = options.autoHeight;

 		return this.each(function() {
 			var $holder = jQuery(this);
 			var instance = $holder.data('Tabset');

 			if (typeof opt === 'object' || typeof opt === 'undefined') {
 				$holder.data('Tabset', new Tabset($holder, options));
 			} else if (typeof method === 'string' && instance) {
 				if (typeof instance[method] === 'function') {
 					args.shift();
 					instance[method].apply(instance, args);
 				}
 			}
 		});
 	};
 }(jQuery, jQuery(window)));



